/* eslint-disable no-unneeded-ternary */
import mapboxgl from 'mapbox-gl';
import pinMap from '../../../assets/pin-map.png';
import {
  ColourScheme,
  Tenant,
  colourSchemes,
  getFromTheme,
} from '../../../theme';
import MapHelpers from '../../map.utils';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';

export function addPointLayer(
  layer: MapLayer | string,
  paint: mapboxgl.CirclePaint = {},
  tenant: Tenant = 'GEOLLECT',
  groupLayerid?: string,
  drawingColour?: string
) {
  const colourScheme: ColourScheme | undefined = colourSchemes.get(
    getFromTheme(tenant, 'colourScheme')
  );
  const map = MapHelpers.getMapInstance();
  const canvas = map.getCanvasContainer();

  // eslint-disable-next-line no-new
  new Promise((resolve) => {
    if (groupLayerid) {
      MapLayerManager.AddLayerinGroups(
        {
          id: layer,
          type: 'circle',
          source: layer,
          layout: {
            visibility: MapLayerVisibility.NOT_VISIBLE,
          },
          paint,
        },
        [groupLayerid]
      );
    } else {
      MapHelpers.addLayer({
        id: layer,
        type: 'circle',
        source: layer,
        layout: {
          visibility: MapLayerVisibility.NOT_VISIBLE,
        },
        paint,
      });
    }

    map.on('mouseenter', layer, () => {
      map.setPaintProperty(
        layer,
        'circle-color',
        drawingColour || colourScheme?.sarTint
      );
      canvas.style.cursor = 'pointer';
    });

    map.on('mouseleave', layer, () => {
      map.setPaintProperty(
        layer,
        'circle-color',
        drawingColour || colourScheme?.drawActive
      );
      canvas.style.cursor = '';
    });

    resolve(MapHelpers);
  });
}

/**
 * Adds a `pin` point to the map with the drop pin icon and text.
 *
 * Differs from the normal addPointLayer() method which uses
 * the default circle instead.
 *
 * @param drawingId - Name of the drawing layer to be created.
 * @param coordinates - List of [x,y] decimal coordinates where the icon will be placed on the map.
 * @param drawingColour - Hex Code of image on map.
 * @param text - The text to display underneath the icon on the map (Can be empty).
 * @param textSize - The font size of the text displayed on the map.
 * @param groupLayerid - Name of the group drawing layer to be created or added to.
 *
 * @example // Creating a point preview
 *         addPointImageLayer(
          'point-preview',
          [
            (drawnFeature.geometry as any).coordinates[0],
            (drawnFeature.geometry as any).coordinates[1],
          ],
          '#ffad1f',
          'PREVIEW',
          12
        );
 *
 */
export function addPointImageLayer(
  drawingId: string,
  coordinates: [number, number],
  drawingColour?: string,
  text?: string,
  textSize?: number,
  groupLayerid?: string
) {
  const map = MapHelpers.getMapInstance();
  // Always remove the layer first to avoid an error
  if (map.getLayer(drawingId)) {
    map.removeLayer(drawingId);
  }
  if (map.getSource(drawingId)) {
    map.removeSource(drawingId);
  }
  if (map.hasImage('custom-marker')) {
    map.removeImage('custom-marker');
  }

  map.loadImage(pinMap, (error, image) => {
    if (error) throw error;
    map.addImage('custom-marker', image as HTMLImageElement, {
      sdf: true,
    });

    if (map.getSource(drawingId)) {
      map.removeSource(drawingId);
    }
    map.addSource(drawingId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [
                coordinates[0], // Need to opt out of type checking
                coordinates[1],
              ],
            },
            properties: {
              title: text ? text : ' ', // Leaving this as an option to add the name of the point on the map in future.
            },
          },
        ],
      },
    });

    if (groupLayerid) {
      MapLayerManager.AddLayerinGroups(
        {
          id: drawingId,
          type: 'symbol',
          paint: {
            'icon-color': drawingColour ? drawingColour : '#ffad1f',
          },
          source: drawingId,
          layout: {
            'icon-image': 'custom-marker',
            'text-field': ['get', 'title'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': textSize,
            'text-offset': [0, 1.25],
            'text-anchor': 'top',
            visibility: MapLayerVisibility.NOT_VISIBLE,
          },
        },
        [groupLayerid]
      );
    } else {
      map.addLayer({
        id: drawingId,
        type: 'symbol',
        paint: {
          'icon-color': drawingColour ? drawingColour : '#ffad1f',
        },
        source: drawingId,
        layout: {
          'icon-image': 'custom-marker',
          'text-field': ['get', 'title'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': textSize,
          'text-offset': [0, 1.25],
          'text-anchor': 'top',
          visibility: MapLayerVisibility.NOT_VISIBLE,
        },
      });
      MapHelpers.setLayerVisibility(drawingId, true);
    }
  });
}
