/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FleetsListVessel, VesselListOwnershipType } from '../../fleets.model';

interface DeleteFleetState {
  deleteFleetsDialogOpen: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
  currentFleets: string[];
  fleetName: string;
  fleetId: string;
  fleetType: VesselListOwnershipType;
  fleetVessels: FleetsListVessel[];
  fleetVesselIds: string[];
}

export const INITIAL_DELETE_FLEET_STATE: DeleteFleetState = {
  deleteFleetsDialogOpen: false,
  loading: false,
  success: false,
  error: false,
  currentFleets: [],
  fleetName: '',
  fleetId: '',
  fleetType: VesselListOwnershipType.Personal,
  fleetVessels: [],
  fleetVesselIds: [],
};

const DeleteFleetsDialogSlice = createSlice({
  name: 'delete fleets dialog',
  initialState: INITIAL_DELETE_FLEET_STATE,
  reducers: {
    setDeleteFleetDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteFleetsDialogOpen = action.payload;
    },
    setDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setDeleteSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
    },
    setDeleteError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setDeleteFleetData(
      state,
      action: PayloadAction<{
        fleetName: string;
        fleetId: string;
        fleetType: VesselListOwnershipType;
        fleetVessels: FleetsListVessel[];
        fleetVesselIds: string[];
      }>
    ) {
      state.fleetName = action.payload.fleetName;
      state.fleetId = action.payload.fleetId;
      state.fleetType = action.payload.fleetType;
      state.fleetVessels = action.payload.fleetVessels;
      state.fleetVesselIds = action.payload.fleetVesselIds;
    },
    resetDeleteState() {
      return INITIAL_DELETE_FLEET_STATE;
    },
  },
});

export const {
  setDeleteFleetDialogOpen,
  setDeleteLoading,
  setDeleteSuccess,
  setDeleteError,
  setDeleteFleetData,
  resetDeleteState,
} = DeleteFleetsDialogSlice.actions;

export default DeleteFleetsDialogSlice.reducer;
