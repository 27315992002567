/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vessel } from '../../models/vessels/vessel.model';
import {
  FleetsFilters,
  FleetsFormItem,
  FleetsListData,
  FleetVisibility,
} from './fleets.model';

export const DEFAULT_FLEETS_FILTERS: FleetsFilters = {
  vesselSource: {
    ais: false,
    riskIntelligence: false,
  },
  search: '',
  open: false,
  vesselListsVessels: [],
};

interface PresetValuesState {
  presetVessels: FleetsFormItem[];
  visibleFleets: FleetVisibility[];
  visibleFleetIds: string[];
  visibleVessels: Vessel[];
  personalFleets: FleetsListData[];
  companyFleets: FleetsListData[];
  protectedFleets: string[];
  currentFleetNames: string[];
  fleetsFilters: FleetsFilters;
  isGlobalAisServiceOn: boolean;
}

export const INITIAL_FLEETS_PANEL_STATE: PresetValuesState = {
  presetVessels: [],
  visibleFleets: [],
  visibleFleetIds: [],
  visibleVessels: [],
  personalFleets: [],
  companyFleets: [],
  protectedFleets: [],
  currentFleetNames: [],
  fleetsFilters: DEFAULT_FLEETS_FILTERS,
  isGlobalAisServiceOn: true,
};

const FleetsPanelSlice = createSlice({
  name: 'fleetsPanel',
  initialState: INITIAL_FLEETS_PANEL_STATE,
  reducers: {
    setFleetsFilters: (
      state,
      action: PayloadAction<Partial<FleetsFilters> | null>
    ) => {
      // Only updates filters explicitly mentioned in action
      state.fleetsFilters = {
        ...state.fleetsFilters,
        ...action.payload,
      };
    },
    setPresetVessels(state, action: PayloadAction<FleetsFormItem[]>) {
      state.presetVessels = action.payload;
    },
    setVisibleFleetIds: (state, action: PayloadAction<string[]>) => {
      state.visibleFleetIds = action.payload;
    },
    toggleFleetVisibility: (
      state,
      action: PayloadAction<{ list: FleetsListData; visible: boolean }>
    ) => {
      const { list, visible } = action.payload;
      const index = state.visibleFleets.findIndex(
        (fleet) => fleet.list.vessel_list_id === list.vessel_list_id
      );

      if (visible) {
        if (index === -1) {
          state.visibleFleets.push({ visible, list });
        } else {
          state.visibleFleets[index] = { visible, list };
        }
      } else if (index !== -1) {
        state.visibleFleets.splice(index, 1);
      }

      state.visibleFleetIds = state.visibleFleets.map(
        (fleet) => fleet.list.vessel_list_id
      );
    },
    removeFleetVisibility: (state, action: PayloadAction<string>) => {
      const fleetIdToDelete = action.payload;

      state.visibleFleets = state.visibleFleets.filter(
        (fleet) => fleet.list.vessel_list_id !== fleetIdToDelete
      );

      state.visibleFleetIds = state.visibleFleets.map(
        (fleet) => fleet.list.vessel_list_id
      );
    },
    setVisibleVessels: (state, action: PayloadAction<Vessel[]>) => {
      state.visibleVessels = action.payload;
    },
    setPersonalFleets: (state, action: PayloadAction<FleetsListData[]>) => {
      state.personalFleets = action.payload;
    },
    setCompanyFleets: (state, action: PayloadAction<FleetsListData[]>) => {
      state.companyFleets = action.payload;
    },
    protectCompanyFleet: (state) => {
      state.protectedFleets = state.companyFleets.map(
        (fleet) => fleet.vessel_list_id
      );
    },
    updateCurrentFleetNames: (state) => {
      state.currentFleetNames = [
        ...state.companyFleets.map((fleet) => fleet.list_name),
        ...state.personalFleets.map((fleet) => fleet.list_name),
      ];
    },
    setIsGlobalAisServiceOn: (state, action: PayloadAction<boolean>) => {
      state.isGlobalAisServiceOn = action.payload;
    },
  },
});

export const {
  setFleetsFilters,
  setPresetVessels,
  setVisibleFleetIds,
  toggleFleetVisibility,
  removeFleetVisibility,
  setPersonalFleets,
  setCompanyFleets,
  protectCompanyFleet,
  updateCurrentFleetNames,
  setIsGlobalAisServiceOn,
  setVisibleVessels,
} = FleetsPanelSlice.actions;

export default FleetsPanelSlice.reducer;
