/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AmendFormikVals,
  FleetsListVessel,
  FleetsVesselBasic,
  PatchFleetsVesselsResponseBody,
  VesselListOwnershipType,
} from '../../fleets.model';

interface AmendFleetState {
  amendFleetsDialogOpen: boolean;
  explainResponseBody: PatchFleetsVesselsResponseBody;
  explainFleetAmendResponse: boolean;
  verifyFleetVesselsOpen: boolean;
  verifyFleetVessels: FleetsVesselBasic[];
  currentFleets: string[];
  fleetName: string;
  fleetId: string;
  fleetType: VesselListOwnershipType;
  fleetVessels: FleetsListVessel[];
  fleetVesselIds: string[];
  formData: AmendFormikVals | null;
  newVesselIds: string[];
  replaceVessels: boolean;
}

export const INITIAL_AMEND_FLEET_STATE: AmendFleetState = {
  amendFleetsDialogOpen: false,
  explainResponseBody: {
    vessels_added: null,
    vessels_deleted: null,
  },
  explainFleetAmendResponse: false,
  verifyFleetVesselsOpen: false,
  verifyFleetVessels: [],
  currentFleets: [],
  fleetName: '',
  fleetId: '',
  fleetType: VesselListOwnershipType.Personal,
  fleetVessels: [],
  fleetVesselIds: [],
  formData: null,
  newVesselIds: [],
  replaceVessels: false,
};

const AmendFleetDialog = createSlice({
  name: 'ammend fleet dialog',
  initialState: INITIAL_AMEND_FLEET_STATE,
  reducers: {
    setAmendFleetDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.amendFleetsDialogOpen = action.payload;
    },
    setAmendFleetData(
      state,
      action: PayloadAction<{
        fleetName: string;
        fleetId: string;
        fleetType: VesselListOwnershipType;
        fleetVessels: FleetsListVessel[];
        fleetVesselIds: string[];
      }>
    ) {
      state.fleetName = action.payload.fleetName;
      state.fleetId = action.payload.fleetId;
      state.fleetType = action.payload.fleetType;
      state.fleetVessels = action.payload.fleetVessels;
      state.fleetVesselIds = action.payload.fleetVesselIds;
    },
    resetAmendState() {
      return INITIAL_AMEND_FLEET_STATE;
    },
    setAmendFleetExplainResponseBody(
      state,
      action: PayloadAction<PatchFleetsVesselsResponseBody>
    ) {
      state.explainResponseBody = action.payload;
    },
    setVerifyFleetVesselsOpen(state, action: PayloadAction<boolean>) {
      state.verifyFleetVesselsOpen = action.payload;
    },
    setFormData(state, action: PayloadAction<AmendFormikVals>) {
      state.formData = action.payload;
    },
    setNewVesselIds(state, action: PayloadAction<string[]>) {
      state.newVesselIds = action.payload;
    },
    setReplaceVessels(state, action: PayloadAction<boolean>) {
      state.replaceVessels = action.payload;
    },
  },
});

export const {
  setAmendFleetDialogOpen,
  setAmendFleetExplainResponseBody,
  setVerifyFleetVesselsOpen,
  setAmendFleetData,
  resetAmendState,
  setFormData,
  setNewVesselIds,
  setReplaceVessels,
} = AmendFleetDialog.actions;

export default AmendFleetDialog.reducer;
