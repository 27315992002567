/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RgbColor } from 'react-colorful';
import {
  ShippingLaneFlag,
  ShippingLaneResolution,
  ShippingLaneShipType,
} from '../../api/shipping-lanes';

export interface ShippingLaneFormValues {
  minLatitude: string | null;
  maxLatitude: string | null;
  minLongitude: string | null;
  maxLongitude: string | null;
  flag: ShippingLaneFlag;
  shipType: ShippingLaneShipType;
  resolution: ShippingLaneResolution;
  laneColour: RgbColor;
  formId: string;
  queryName: string;
}

interface ShippingLaneState {
  currentFormId: string;
  formsById: { [key: string]: ShippingLaneFormValues };
}

export const INITIAL_SHIPPING_LANE_STATE: ShippingLaneState = {
  currentFormId: '',
  formsById: {},
};

const shippingLanesSlice = createSlice({
  name: 'shippingLanesData',
  initialState: INITIAL_SHIPPING_LANE_STATE,
  reducers: {
    clearFormsById: (state) => {
      state.formsById = {};
    },
    setFormsById: (state, action: PayloadAction<ShippingLaneFormValues>) => {
      const { formId } = action.payload;
      state.formsById[formId] = action.payload;
    },
    removeFormById: (state, action: PayloadAction<string>) => {
      const formId = action.payload;
      delete state.formsById[formId];
    },
  },
});

export const { removeFormById, setFormsById, clearFormsById } =
  shippingLanesSlice.actions;

export default shippingLanesSlice.reducer;
