/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  FleetsVessel,
  NormalisedFleetsVessels,
} from '../../maritime-menu-options/fleets-panel/fleets.model';

const normaliseVessels = (vessels: FleetsVessel[]): NormalisedFleetsVessels =>
  vessels.reduce<NormalisedFleetsVessels>(
    (acc, vessel) => {
      if (acc.byId[vessel.vessel_id]) {
        acc.byId[vessel.vessel_id].vessel_list_id = Array.from(
          new Set([
            ...acc.byId[vessel.vessel_id].vessel_list_id,
            ...vessel.vessel_list_id,
          ])
        );
      } else {
        acc.byId[vessel.vessel_id] = {
          ...vessel,
          vessel_list_id: Array.isArray(vessel.vessel_list_id)
            ? vessel.vessel_list_id
            : [vessel.vessel_list_id],
        };
      }

      const imo = vessel.imo ?? 'undefined';
      const mmsi = vessel.mmsi ?? 'undefined';
      const imoMmsiKey = `${imo}-${mmsi}`;

      if (!acc.idsByImoMmsi[imoMmsiKey]) {
        acc.idsByImoMmsi[imoMmsiKey] = [];
      }
      if (!acc.idsByImoMmsi[imoMmsiKey].includes(vessel.vessel_id)) {
        acc.idsByImoMmsi[imoMmsiKey].push(vessel.vessel_id);
      }
      if (!acc.allIds.includes(vessel.vessel_id)) {
        acc.allIds.push(vessel.vessel_id);
      }
      return acc;
    },
    {
      byId: {},
      idsByImoMmsi: {},
      allIds: [],
    }
  );

export const updateFleet = (
  state: NormalisedFleetsVessels | null,
  newVessels: FleetsVessel[]
): NormalisedFleetsVessels => {
  if (!state) {
    return normaliseVessels(newVessels);
  }
  const normalizedNewVessels = normaliseVessels(newVessels);

  const updatedById = new Map(Object.entries(state.byId));
  const updatedIdsByImoMmsi = new Map(Object.entries(state.idsByImoMmsi));
  const updatedAllIds = new Set(state.allIds);

  Object.entries(normalizedNewVessels.byId).forEach(([id, vessel]) => {
    if (updatedById.has(id)) {
      const existingVessel = updatedById.get(id) as FleetsVessel;
      // Update existing vessel details with newer information
      updatedById.set(id, { ...existingVessel, ...vessel });
      const updatedVessel = updatedById.get(id) as FleetsVessel;
      // ensure vessel_list_id includes all lists the vessel is part of
      updatedVessel.vessel_list_id = Array.from(
        new Set([...existingVessel.vessel_list_id, ...vessel.vessel_list_id])
      );
      updatedById.set(id, updatedVessel);
    } else {
      updatedById.set(id, vessel);
    }

    const imo = vessel.imo ?? 'undefined';
    const mmsi = vessel.mmsi ?? 'undefined';
    const imoMmsiKey = `${imo}-${mmsi}`;

    if (!updatedIdsByImoMmsi.has(imoMmsiKey)) {
      updatedIdsByImoMmsi.set(imoMmsiKey, []);
    }
    if (!updatedIdsByImoMmsi.get(imoMmsiKey)!.includes(id)) {
      updatedIdsByImoMmsi.get(imoMmsiKey)!.push(id);
    }

    updatedAllIds.add(id);
  });

  return {
    byId: Object.fromEntries(updatedById),
    idsByImoMmsi: Object.fromEntries(updatedIdsByImoMmsi),
    allIds: Array.from(updatedAllIds),
  };
};

interface FleetVessels {
  fleetsVesselsLoading: boolean;
  fleetsVesselsError: boolean;
  fleetsVessels: NormalisedFleetsVessels | null;
  vesselTypeDefaultColouring: boolean;
}

export const INITIAL_FLEET_VESSELS_STATE: FleetVessels = {
  fleetsVesselsLoading: false,
  fleetsVesselsError: false,
  fleetsVessels: null,
  vesselTypeDefaultColouring: false,
};

const FleetsVesselsSlice = createSlice({
  name: 'fleetsVessels',
  initialState: INITIAL_FLEET_VESSELS_STATE,
  reducers: {
    setFleetsVesselsLoading: (state, action: PayloadAction<boolean>) => {
      state.fleetsVesselsLoading = action.payload;
    },
    setFleetsVesselsError: (state, action: PayloadAction<boolean>) => {
      state.fleetsVesselsError = action.payload;
    },
    setFleetsVessels: (state, action: PayloadAction<FleetsVessel[]>) => {
      state.fleetsVessels = updateFleet(state.fleetsVessels, action.payload);
      state.fleetsVesselsError = false;
    },
    setVesselTypeDefaultColouring: (state, action: PayloadAction<boolean>) => {
      state.vesselTypeDefaultColouring = action.payload;
    },
    updateFleetsVesselsVessel: (state, action: PayloadAction<FleetsVessel>) => {
      if (state.fleetsVessels) {
        state.fleetsVessels.byId[action.payload.vessel_id] = action.payload;
      }
    },
  },
});

export const {
  setFleetsVesselsLoading,
  setFleetsVesselsError,
  setFleetsVessels,
  updateFleetsVesselsVessel,
  setVesselTypeDefaultColouring,
} = FleetsVesselsSlice.actions;

export default FleetsVesselsSlice.reducer;
