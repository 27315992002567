export const GEOLLECT_DEV = '72b80d88-9493-4368-b675-021d1e631e37';
export const GEOLLECT_STAGING = 'c5286322-5343-4094-971b-666da70ab470';
export const GEOLLECT_PROD = '0b474cdb-3a8c-45b3-91c1-5bdfef855051';
export const DEFENCE_OSINT_CENTRE_DEV = '85052a07-b91a-4011-9524-3e5588dcf153';
export const UKHO_DEV = '30d3fb13-39cf-4905-90a3-0461087f01d1';
export const END2END = '043b731a-e04e-4544-a301-88ac0bd852ee';
export const WEST_PROD = 'cf3c1905-859b-46fd-8c2f-d967a5cc5eae'; // GEOLLECT TENANT
export const ROKE_ANALYSIS_TEAM_PROD = 'bbf77b2e-aefa-4c59-897d-154c7550eb8e'; // GEOLLECT TENANT

export const COMPANIES_ALLOWED_ACCESS_TO_OBSERVATIONS_POC = [
  GEOLLECT_DEV,
  GEOLLECT_STAGING,
  GEOLLECT_PROD,
  DEFENCE_OSINT_CENTRE_DEV,
  UKHO_DEV,
];

export const COMPANIES_ALLOWED_ACCESS_TO_TIMELINE = [
  WEST_PROD,
  ROKE_ANALYSIS_TEAM_PROD,
];

export const COMPANIES_ALLOWED_ACCESS_TO_ADTECH_POC = [
  GEOLLECT_DEV,
  GEOLLECT_STAGING,
  GEOLLECT_PROD,
  DEFENCE_OSINT_CENTRE_DEV,
  UKHO_DEV,
];

export const COMPANIES_ALLOWED_ACCESS_TO_CAPELLA = [
  GEOLLECT_DEV,
  GEOLLECT_STAGING,
  GEOLLECT_PROD,
];

export const COMPANIES_ALLOWED_ACCESS_TO_UNSEEN_LABS = [
  GEOLLECT_DEV,
  GEOLLECT_STAGING,
  GEOLLECT_PROD,
];

export const COMPANIES_ALLOWED_ACCESS_TO_SUBSEA_INFRASTRUCTURE_POC = [
  GEOLLECT_DEV,
  GEOLLECT_PROD,
  GEOLLECT_STAGING,
  UKHO_DEV,
];

export const COMPANIES_ALLOWED_ACCESS_TO_ESA = [
  GEOLLECT_DEV,
  GEOLLECT_STAGING,
  GEOLLECT_PROD,
  END2END,
];

export const COMPANIES_ALLOWED_ACCESS_TO_GLOBAL_AIS = [GEOLLECT_DEV];

// These License IDs are for temporary development purposes only. During productisation we will migrate to storing these in the database.
export const AVCS_LICENSE_IDS: { [key: string]: string } = {
  [GEOLLECT_DEV]: '99081',
  [GEOLLECT_STAGING]: '',
  [GEOLLECT_PROD]: '',
  [UKHO_DEV]: '99081',
};

export const COMPANIES_ALLOWED_ACCESS_TO_FLEETS = [GEOLLECT_DEV];

export const COMPANIES_ALLOWED_ACCESS_TO_HISTORIC_VESSELS = [
  GEOLLECT_DEV,
  GEOLLECT_STAGING,
  GEOLLECT_PROD,
];
